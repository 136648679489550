<script setup>
import { ref } from "vue";
import { apiService } from "./lib/ChatService";
import { saveAs } from "file-saver";
apiService.loadSocket();

function rephrase() {
  loading.value = true;
  const value = input.value.trim();
  if (!value) {
    loading.value = false;
    return;
  }
  apiService.send(input.value.trim());
}

function copy() {
  navigator.clipboard.writeText(output.value);
}

function download() {
  const blob = new Blob([output.value], { type: "text/plain;charset=utf-8" });
  saveAs(blob, "download.txt");
}
const input = ref("");
const output = ref("");
const loading = ref(false);

apiService.reciever((data) => {
  output.value = data;
  loading.value = false;
});
</script>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<template>
  <div class="flex flex-col h-[100svh]">
    <nav
      class="max-h-[7vh] h-[7vh] backdrop-brightness-125 flex items-center justify-between p-3 shadow-md"
    >
      <div class="flex items-center justify-start h-full">
        <img class="mx-3 rounded-full h-5/6" src="@/assets/logo.png" alt="" />
        <div>
          <div class="text-base font-semibold text-slate-700 uppercase">
            magpie AI
          </div>
        </div>
      </div>
    </nav>
    <div class="flex flex-col md:flex-row flex-1 bg-slate-100 gap-3 p-3">
      <textarea
        :disabled="loading"
        v-model="input"
        class="resize-none flex-1 rounded-md p-6 md:p-8 focus:border-dark-primary-medium/50 focus:outline-none border-2"
        name="input"
        id=""
      ></textarea>
      <div class="relative">
        <div
          class="absolute flex md:grid p-2 place-items-center justify-center border left-1/2 -translate-y-1/2 -translate-x-1/2 md:top-1/2 md:-translate-y-full md:-translate-x-1/2 bg-white shadow-lg rounded-md"
        >
          <button
            :disabled="loading"
            @click="rephrase"
            class="shadow-md border w-full aspect-square rounded-md disabled:shadow-none disabled:bg-slate-100 p-2 m-1 hover:bg-dark-primary-medium hover:text-white transition-all"
          >
            <i
              v-if="!loading"
              class="fa-solid fa-chevron-right rotate-90 md:rotate-0"
            ></i>
            <i v-else class="fa-solid fa-spinner animate-spin"></i>
          </button>
          <button
            @click="
              () => {
                output = '';
                input = '';
              }
            "
            :disabled="loading"
            class="shadow-md border w-full aspect-square rounded-md disabled:shadow-none disabled:bg-slate-100 p-2 m-1 hover:bg-dark-primary-medium hover:text-white transition-all"
          >
            <i class="fa-solid fa-rotate-right"></i>
          </button>
          <button
            :disabled="loading"
            @click="copy"
            class="shadow-md border w-full aspect-square rounded-md disabled:shadow-none disabled:bg-slate-100 p-2 m-1 hover:bg-dark-primary-medium hover:text-white transition-all"
          >
            <i class="fa-regular fa-copy"></i>
          </button>
          <button
            :disabled="loading"
            @click="download"
            class="shadow-md border w-full aspect-square rounded-md disabled:shadow-none disabled:bg-slate-100 p-2 m-1 hover:bg-dark-primary-medium hover:text-white transition-all"
          >
            <i class="fa-solid fa-download"></i>
          </button>
        </div>
      </div>
      <textarea
        v-model="output"
        class="resize-none flex-1 rounded-md p-6 md:p-8 focus:border-dark-primary-medium/50 focus:outline-none border-2"
        name="output"
        id=""
      ></textarea>
    </div>
  </div>
</template>

<style></style>
