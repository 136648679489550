import { io } from "socket.io-client";

const SOCKET_URL = "https://mirror16.gieom.com";

class ApiService {
  #socket;

  async loadSocket() {
    // console.log(SOCKET_URL);
    this.socket = io(SOCKET_URL, {
      reconnection: true,
      reconnectionAttempts: 3,
      reconnectionDelay: 1000,
      //transports: ['websocket'],
      secure: true,
    });

    this.socket.on(SOCKET_URL, {
      reconnection: true,
      reconnectionAttempts: 3,
      reconnectionDelay: 1000,
      //transports: ['websocket'],
      secure: true,
    });

    this.socket.on("connect", () => {
      console.log("Connected");
    });
  }

  send(data) {
    if (!this.socket) this.loadSocket();
    this.socket.emit("regenerate_content", data);
  }

  reciever(callback) {
    if (!this.socket) this.loadSocket();
    this.socket.on("regenerated_result", (data) => {
      callback(data);
    });
  }
}

export const apiService = new ApiService();
